import _ from 'lodash';
import twemoji from 'twemoji';

const mixin = {
  data() {
    let defaultMeta = {
      title: this.$t('general.' + process.env.APP_NAME),
      description: '',
    };

    return {
      defaultMeta,
      meta: defaultMeta,
      locales: [],
      graphSetup: {
        type: 'WebPage',
        createdAt: '',
        updatedAt: '',
      },
    };
  },
  head() {
    return this.routeMixinHead();
  },
  created() {
    this.setupI18nRoutes(this.locales);
  },
  methods: {
    routeMixinHead() {
      if (!this.meta) {
        this.meta = this.defaultMeta;
      }

      let meta = {
        meta: [],
        __dangerouslyDisableSanitizers: ['script'],
        script: [{ type: 'application/ld+json', json: this.graph }],
      };

      meta.title = !!this.meta?.title ? this.meta?.title : this.defaultMeta.title;

      if (meta.title) {
        meta.meta.push({
          hid: 'og:title',
          name: 'og:title',
          content: meta.title,
        });
      }

      if (this.meta?.description) {
        meta.meta.push({
          hid: 'description',
          name: 'description',
          content: this.meta.description,
        });

        meta.meta.push({
          hid: 'og:description',
          name: 'og:description',
          content: this.meta.description,
        });
      }

      meta.meta.push({
        hid: 'og:image',
        name: 'og:image',
        content: this.meta?.image ? this.meta.image : `${process.env.APP_URL}/images/social-image.jpg`,
      });

      if (this.schema) {
        meta.script.push({ type: 'application/ld+json', json: this.schema });
      }

      let canonical;

      const hreflang = this.currentLocale?.code ?? 'nl';
      const alternates = this.formattedAlternates();
      const canonicalFromAlternates = alternates.find((alternate) => alternate.hreflang === hreflang);

      if (canonicalFromAlternates) {
        canonical = { rel: 'canonical', href: canonicalFromAlternates.href };
      }

      meta.link = [
        ...alternates,
        ...(canonical ? [canonical] : []),
      ];

      return {
        ...meta,
      };
    },

    formattedAlternates() {
      return this.$i18n.locales.map((locale) => {
        let href = `${process.env.APP_URL}${this.switchLocalePath(locale.code)}/`;

        // Remove home/ slug from URL
        href = href.replace(/home\/$/, '');

        return {
          hid: `alternate-${locale.code}`,
          rel: 'alternate',
          hreflang: locale.code,
          href,
          title: locale.lang,
        };
      });
    },

    setupI18nRoutes(locales) {
      const params = {};

      for (let { code } of this.$i18n.locales) {
        if (!params[code]) {
          params[code] = {};
        }

        for (let paramKey in locales) {
          params[code][paramKey] =
            locales[paramKey][code] ??
            locales[paramKey][this.$i18n.defaultLocale];
        }
      }

      this.$store.dispatch('i18n/setRouteParams', params);
    },
  },
  computed: {
    graph() {
      const graphData = this.graphSetup;
      const meta = this.meta;
      const appName = process.env.APP_NAME;
      const url = process.env.APP_URL;
      const uri = url + this.$route.path;
      const locale = this.$i18n.locale;

      const websiteUrl = `${url}#website`;
      const organizationUrl = `${url}#organization`;
      const breadcrumbUri = `${uri}#breadcrumb`;
      const logoUrl = `${url}#/schema/logo/image/`;
      const logoImageUrl = `${url}/images/logo.webp`;

      return {
        '@context': 'https://schema.org',
        '@graph': [
          {
            '@type': graphData.type,
            '@id': uri,
            url: uri,
            name: meta.title,
            isPartOf: {
              '@id': websiteUrl,
            },
            about: {
              '@id': organizationUrl,
            },
            datePublished: graphData.createdAt,
            dateModified: graphData.updatedAt,
            description: meta.description,
            breadcrumb: {
              '@id': breadcrumbUri,
            },
            inLanguage: locale,
            potentialAction: [
              {
                '@type': 'ReadAction',
                target: [url],
              },
            ],
          },
          {
            '@type': 'BreadcrumbList',
            '@id': breadcrumbUri,
            itemListElement: [...this.breadcrumbList],
          },
          {
            '@type': 'WebSite',
            '@id': websiteUrl,
            url: url,
            name: appName,
            description: '',
            publisher: {
              '@id': organizationUrl,
            },
            inLanguage: locale,
          },
          {
            '@type': 'Organization',
            '@id': organizationUrl,
            name: appName,
            url: url,
            logo: {
              '@type': 'ImageObject',
              inLanguage: locale,
              '@id': logoUrl,
              url: logoImageUrl,
              contentUrl: logoImageUrl,
              width: 500,
              height: 135,
              caption: appName,
            },
            image: {
              '@id': logoUrl,
            },
          },
        ],
      };
    },
    breadcrumbList() {
      const url = process.env.APP_URL;

      // Breadcrumbs for the graph.
      let breadcrumbs = [];
      // Breadcrumbs delivered by the page.
      let pageBreadcrumbs = this.breadcrumbs ?? [];

      // Add the default home breadcrumb.
      const homeBreadcrumb = {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
      };

      // If there are page breadcrumbs add the url to home.
      if (pageBreadcrumbs.length > 0) {
        homeBreadcrumb.item = url;
      }
      breadcrumbs.push(homeBreadcrumb);

      // Loop over the page breadcrumbs and add the to the breadcrumb list.
      for (let index in pageBreadcrumbs) {
        let item = pageBreadcrumbs[index];
        index = Number(index);

        let breadcrumb = {
          '@type': 'ListItem',
          position: index + Number(2),
          name: item.title,
        };

        if (index + Number(1) < pageBreadcrumbs.length && !!item.href) {
          let href = item.href;

          if (!href.startsWith(url)) {
            href = url + href;
          }

          breadcrumb.item = href;
        }

        breadcrumbs.push(breadcrumb);
      }

      return breadcrumbs;
    },

    currentLocale() {
      const locales = this.$i18n.locales;

      let index = _.findIndex(locales, { code: this.$i18n.locale });

      if (!index) {
        index = _.findIndex(locales, { code: this.$i18n.defaultLocale });
      }

      return locales[index];
    },
  },
  mounted() {
    setTimeout(() => twemoji.parse(document.body), 300);
  },
};

export default mixin;
