
export default {
  props: {
    components: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
    },
    isHome: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    filteredComponents() {
      return this.components;
      if (!this.components && typeof this.components !== 'array') {
        return;
      }

      let components = [];

      for (let component of this.components) {
        let type = null;

        switch (component.component_type) {
          case 'PageHeader':
            type = 'PageHeader';
            break;
          case 'PageMaisons':
            type = 'PageMaisons';
            break;
        }

        if (type === null) {
          continue;
        }

        components.push({
          type,
          ...component,
        });
      }

      return components;
    },
  },
};
