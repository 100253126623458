import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=7081a97c&"
import script from "./_page.vue?vue&type=script&lang=js&"
export * from "./_page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageComponentRenderer: require('/home/forge/www.champagne-booking.com/components/PageComponentRenderer.vue').default,TheLayout: require('/home/forge/www.champagne-booking.com/components/TheLayout.vue').default})
